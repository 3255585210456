import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import {
  EmailValidationMessage,
  EmailValidator,
  maxLengthValidationMessage,
  maxValidationMessage,
  minLengthValidationMessage,
  minValidationMessage,
} from './validators';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule.forRoot({
      types: [
        { name: 'time-picker', component: TimePickerComponent },
      ],
      validators: [
        { name: 'email', validation: EmailValidator },
      ],
      validationMessages: [
        { name: 'email', message: EmailValidationMessage },
        { name: 'required', message: 'This field is required' },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
    ],
    }),
    FormlyMaterialModule,
  ],
  exports: [
    FormlyModule,
    NgxMatTimepickerModule,
  ]
})
export class FormlyFormModule { }
