export class MenuItem {
  label: string;
  path: string;
  icon?: string;
  external?: boolean;
  children?: MenuItem[];

  constructor(label: string, icon: string, url: string, external = false) {
    this.label = label;
    this.icon = icon;
    this.path = url;
    this.external = external;
  }
}
