export interface Environment {
  production: boolean;
  env: 'local' | 'dev' | 'stage' | 'prod';
  api: {
    host: string;
  };
  auth: {
    region: string;
    pool_id: string;
    client_id: string;
    EMPLOYEE_DEFAULT_PASSPHRASE: string;
  };
  ux: {
    DEBOUNCE_TIME: number;
  };
}

const UX_CONSTANTS = {
  DEBOUNCE_TIME: 275,
};

export const baseEnvironment = {
  production: false,
  ux: UX_CONSTANTS,
};
