import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@common/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authorized$
      .pipe(
        map(authorized => {
          if (!authorized) {
            // not logged in so redirect to signIn page with the return url
            void this.router.navigate(['/auth/sign-in'], {queryParams: {returnUrl: state.url}});
            return false;
          }

          return true;
        }),
        catchError(() => of(false)),
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedGuard {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.authorized$
      .pipe(
        map(authorized => {
          if (authorized) {
            void this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        }),
        catchError(() => of(true)),
      );
  }

}
