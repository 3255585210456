import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from '../service/auth.service';
import { Role } from '../model/auth';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ifRole]' })
export class IfRoleDirective implements OnInit, OnDestroy {
  user$!: Subscription;
  private allowedRoles: Role[] = [];
  @Input() set ifRole(roles: Role | Role[]) {
    if (Array.isArray(roles)) {
      this.allowedRoles = roles;
    } else {
      this.allowedRoles = [roles];
    }
  }
  @Input() ifRoleElse?: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private vcRef: ViewContainerRef,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.user$ = this.authService.authUser$.pipe(
      tap(() => this.vcRef.clear()),
      map(user => user ? this.allowedRoles.includes(user.role) : false),
    ).subscribe((accessAllowed: boolean) => {
      if (accessAllowed) {
        this.vcRef.createEmbeddedView(this.templateRef);
        this.cdRef.markForCheck();
      } else if (this.ifRoleElse) {
        this.vcRef.createEmbeddedView(this.ifRoleElse);
        this.cdRef.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    this.user$.unsubscribe();
  }
}
