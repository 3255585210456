import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationHandler } from '@common/helper/notification.handler';
import { AuthService } from '@common/service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError(err => {
        if (err.status !== 403) {
          NotificationHandler.handleError(err);
        } else {
          console.error(err);
        }

        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          this.authService.signOut().subscribe(() => {
            void this.router.navigate(['/auth/sign-in'], { replaceUrl: true });
          });
        }
        return throwError(() => err);
      }));
  }
}
