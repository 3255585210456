import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

@Component({
  standalone: true,
  templateUrl: './time-picker.component.html',
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMatTimepickerModule,
    FormlyModule,
  ]
})
export class TimePickerComponent extends FieldType<FieldTypeConfig> {}
