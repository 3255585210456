import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

enum ViewportSizeClass {
  Mobile = 'mobile-viewport',
  Small = 'small-viewport',
  Large = 'large-viewport',
}

@Directive({
  standalone: true,
  selector: '[appViewportSize]'
})
export class ViewportSizeDirective implements OnInit, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver,
  ) { }


  ngOnInit(): void {
    this.subscription = this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.Small, Breakpoints.Large])
      .subscribe((state: BreakpointState) => {
        const className = this.getClass(state);
        if (className) {
          this.setClass(className);
        }
      });
  }

  private setClass(className: ViewportSizeClass): void {
    this.removeAppliedClassNames();
    this.renderer.addClass(this.el.nativeElement, className);
  }

  private getClass(state: BreakpointState): ViewportSizeClass | undefined {
    if (state.breakpoints[Breakpoints.HandsetPortrait]) {
      return ViewportSizeClass.Mobile;
    }

    if (state.breakpoints[Breakpoints.Small]) {
      return ViewportSizeClass.Small;
    }

    if (state.breakpoints[Breakpoints.Large]) {
      return ViewportSizeClass.Large;
    }

    return undefined;
  }

  private removeAppliedClassNames(): void {
    const classNames = Object.values(ViewportSizeClass);
    classNames.forEach(name => {
      if (this.el.nativeElement.classList.contains(name)) {
        this.renderer.removeClass(this.el.nativeElement, name);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
