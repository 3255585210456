import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

import { Subject, take, takeUntil, } from 'rxjs';
import { ThemePalette } from '@angular/material/core';

export type ButtonAppearance = 'flat' | 'raised' | 'stroked' | 'basic' | 'icon' | 'fab' | 'mini-fab' | 'fab-extended';
export type ButtonColor = 'basic' | 'primary' | 'accent' | 'warn' | undefined;
export type ButtonMode = 'click' | 'confirmation';

@Component({
  standalone: true,
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatBadgeModule],
})
export class GenericButtonComponent implements OnDestroy {
  @ViewChild('confirmationDialogTpl') confirmationDialogTpl?: TemplateRef<ElementRef>;
  protected dialogRef?: MatDialogRef<GenericButtonComponent | ElementRef<unknown>>;

  @Input() appearance: ButtonAppearance = 'flat';
  @Input() color: ButtonColor = 'basic';
  @Input() badge?: string | number;
  @Input() badgeColor?: ThemePalette;
  @Input() padding = 5;
  @Input() disabled = false;
  @Input() loading: boolean | null = false;

  @Input() mode: ButtonMode = 'click'
  @Input() confirmationMessage = 'Confirm your action?';
  @Input() confirmationDescription?: string;
  @Input() template?: TemplateRef<unknown>;
  @Input() templateContext?: unknown;
  @Input() hidden = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() confirm = new EventEmitter<any>();

  private readonly onDestroy$ = new Subject<void>();
  constructor(
    private dialog: MatDialog,
  ) { }

  protected click(): void {
    if (this.mode === 'confirmation') {
      this.openConfirmationDialog();
      return;
    }
  }

  protected proxyClick(event: MouseEvent): void {
    if (this.disabled) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  protected openConfirmationDialog(): void {
    if (this.confirmationDialogTpl) {
      this.dialogRef = this.dialog.open<ElementRef, unknown, [boolean, unknown]>(this.confirmationDialogTpl);
      this.dialogRef.afterClosed().pipe(
        take(1),
        takeUntil(this.onDestroy$),
      ).subscribe(([confirmed, data]: [boolean, unknown]) => {
        if (confirmed) {
          this.confirm.emit(data);
        }
      });
    }
  }

  protected declineFn = () => {
    return this.dialogRef?.close([false, undefined]);
  }

  protected confirmFn = (data?: unknown) => {
    return this.dialogRef?.close([true, data]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
