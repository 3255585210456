import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'center-content-holder',
  templateUrl: './center-content-holder.component.html',
  styleUrls: ['./center-content-holder.component.scss']
})
export class CenterContentHolderComponent {
}
