import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, EMPTY, from, Observable, of, } from 'rxjs';
import { catchError, map, switchMap, tap, } from 'rxjs/operators';
import Auth, { CognitoUser, } from '@aws-amplify/auth';

import { Role, Session } from '../model/auth';
import { User, UserApiService } from '@app/core/user';

interface CognitoUserAttributes {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  preferred_username?: string;
  'cognito:groups': Role[];
  sub: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly authUser$ = new BehaviorSubject<User | undefined>(undefined);
  readonly authorized$ = this.authUser$.pipe(map(Boolean))

  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private employeeApiService: UserApiService,
  ) {
  }

  private static getAttributes(cognitoUser: CognitoUser): CognitoUserAttributes {
    return ((cognitoUser as unknown) as { attributes: CognitoUserAttributes }).attributes;
  }

  private getCognitoUserId(): Observable<string> {
    return from(Auth.currentAuthenticatedUser({ bypassCache: false })).pipe(
      map((data: CognitoUser) => {
        return AuthService.getAttributes(data)?.sub;
      }),
      catchError(() => {
        void this.router.navigate(['/auth/sign-in'], { replaceUrl: true });
        return EMPTY;
      })
    );
  }

  checkAuth(): Observable<User | undefined> {
    return this.getCognitoUserId().pipe(
      switchMap(userId => {
        if (!userId) {
          return of(undefined);
        }
        return this.employeeApiService.fetchUser(userId);
      }),
      tap(user => {
        this.authUser$.next(user);
      }),
    );
  }

  getSession(): Observable<Session> {
    return from(Auth.currentSession())
      .pipe(
        map(cognitoSession => {
          const groups = cognitoSession.getAccessToken().payload['cognito:groups'] as string[] || [];
          const roles = groups.map((group: string) => group as Role);
          return new Session(roles, cognitoSession.getIdToken().getJwtToken());
        }),
        catchError(() => {
          return of(new Session([], undefined));
        })
      );
  }

  clearSession() {
    this.dialogRef.closeAll();
    this.authUser$.next(undefined);
    void this.router.navigate(['/auth/sign-in'], { replaceUrl: true });
  }

  signIn(username: string, password: string): Observable<CognitoUser> {
    return from(Auth.signIn(username, password))
      .pipe(
        switchMap((cognitoUser) => {
          if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return of(cognitoUser);
          }

          const sub = cognitoUser.signInUserSession.idToken.payload.sub;

          return this.employeeApiService.fetchUser(sub).pipe(
            tap(user => this.authUser$.next(user)),
            map(() => cognitoUser),
          );
        }),
      );
  }


  signOut(): Observable<void> {
    return from(Auth.signOut())
      .pipe(
        tap(() => {
          this.clearSession()
        })
      );
  }

}
