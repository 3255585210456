import {
  Overlay,
  OverlayKeyboardDispatcher,
  OverlayOutsideClickDispatcher,
  OverlayPositionBuilder,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  NgZone,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { DynamicOverlayContainer } from './dynamic-overlay-container';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT, Location } from '@angular/common';

@Injectable()
export class DynamicOverlay extends Overlay {

  private readonly dynamicOverlayContainer: DynamicOverlayContainer;
  private renderer: Renderer2;

  constructor(scrollStrategies: ScrollStrategyOptions,
              overlayContainer: DynamicOverlayContainer,
              componentFactoryResolver: ComponentFactoryResolver,
              positionBuilder: OverlayPositionBuilder,
              keyboardDispatcher: OverlayKeyboardDispatcher,
              injector: Injector,
              ngZone: NgZone,
              @Inject(DOCUMENT) document: unknown,
              directionality: Directionality,
              location: Location,
              outsideClickDispatcher: OverlayOutsideClickDispatcher,
              rendererFactory: RendererFactory2) {
    super(
      scrollStrategies,
      overlayContainer,
      componentFactoryResolver,
      positionBuilder,
      keyboardDispatcher,
      injector,
      ngZone,
      document,
      directionality,
      location,
      outsideClickDispatcher);

    this.renderer = rendererFactory.createRenderer(null, null);

    this.dynamicOverlayContainer = overlayContainer;
  }

  public setContainerElement(containerElement: HTMLElement): void {
    this.renderer.setStyle(containerElement, 'transform', 'translateZ(0)');
    this.dynamicOverlayContainer.setContainerElement(containerElement);
  }
}
