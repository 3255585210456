<a *ngIf="!href && !externalRouterLink" [routerLink]="routerLink" [target]="external ? '_blank' : '_self'"
   class="color-primary">
  <mat-icon *ngIf="icon" class="url-icon">{{icon}}</mat-icon>
  <span>{{label}}</span>
  <mat-icon *ngIf="external" class="external-url-icon">open_in_new</mat-icon>
</a>

<a *ngIf="href || externalRouterLink" [href]="href || externalRouterLink" [target]="external ? '_blank' : '_self'"
   class="color-primary">
  <mat-icon *ngIf="icon" class="url-icon">{{icon}}</mat-icon>
  <span>{{label}}</span>
  <mat-icon *ngIf="external" class="external-url-icon">open_in_new</mat-icon>
</a>
