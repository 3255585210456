import { Role } from './Role';

export class Session {

  public token?: string;
  public roles: Role[];

  constructor(roles: Role[], token?: string) {
    this.token = token;
    this.roles = roles;
  }

  isValid(): boolean {
    return this.token != null && this.token.length > 0;
  }

  hasPermission(role: Role): boolean {
    return !!this.roles?.length && this.roles.includes(role);
  }
}
