import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environment/environment';
import { enableProdMode } from '@angular/core';
import Auth from '@aws-amplify/auth';


if (environment.production) {
  enableProdMode();
}

Auth.configure({
  region: environment.auth.region,
  userPoolId: environment.auth.pool_id,
  userPoolWebClientId: environment.auth.client_id,
  mandatorySignIn: true,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
