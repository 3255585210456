<button *ngIf="!link && label"
        mat-raised-button
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [style.width]="width"
        class="button"
        [disabled]="disabled"
        (click)="clicked.emit($event)">
  <mat-icon class="btn-icon" *ngIf="icon">{{icon}}</mat-icon>
  {{label}}
</button>
<button *ngIf="icon && secondary && !label && !link"
        mat-icon-button
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [class.destructive-btn]="destructive"
        class="icon-btn"
        [disabled]="disabled"
        (click)="clicked.emit($event)">
  <mat-icon class="btn-icon" *ngIf="icon">{{icon}}</mat-icon>
</button>

<button *ngIf="icon && !secondary && !label && !link"
        mat-mini-fab
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [class.destructive-btn]="destructive"
        class="icon-btn"
        [disabled]="disabled"
        (click)="clicked.emit($event)">
  <mat-icon class="btn-icon" *ngIf="icon">{{icon}}</mat-icon>
</button>

<a *ngIf="!icon && link && !routerLink"
   mat-raised-button
   class="button"
   [href]="href"
   [target]="target">{{label}}</a>

<a *ngIf="icon && link && !routerLink"
   mat-icon-button
   class="button"
   [href]="href"
   [target]="target">
  <mat-icon>{{icon}}</mat-icon>
</a>

<a *ngIf="!icon && link && routerLink"
   mat-raised-button
   class="button"
   [routerLink]="routerLink">{{label}}</a>
