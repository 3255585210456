import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '@environment';
import { AuthService } from '@common/service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getSession()
      .pipe(switchMap(session => {

        if (!session.isValid()) {
          this.authService.clearSession();
          return EMPTY;
        }

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${session.token}` || ''
          },
          url: `${environment.api.host}${request.url}`,
        });
        return next.handle(request);
      }));
  }

}
