<mat-form-field class="full-width form-input" [class.small-text]="compact">
  <mat-label>{{ label }}</mat-label>
  <label>
    <input *ngIf="type !== 'textarea' && !errorStateMatcher && !autoComplete" matInput #input
           [placeholder]="placeholder || ''"
           [type]="inputType"
           [formControl]="control"
           [autocomplete]="autocomplete"
           [step]="step"
           [required]="required"
           (blur)="handleOnBlur()"
           (keyup.enter)="handleKeyUpEnter()"/>

    <input *ngIf="type !== 'textarea' && errorStateMatcher && !autoComplete" matInput #input
           [placeholder]="placeholder || ''"
           [type]="inputType"
           [formControl]="control"
           [autocomplete]="autocomplete"
           [required]="required"
           [errorStateMatcher]="errorStateMatcher"/>

    <input *ngIf="type !== 'textarea' && autoComplete" matInput #input
           [placeholder]="placeholder || ''"
           [type]="inputType"
           [formControl]="control"
           autocomplete="off"
           [required]="required">

    <textarea *ngIf="type === 'textarea'" matInput #input
              [placeholder]="placeholder || ''"
              [formControl]="control"
              [required]="required" class="text-input"></textarea>
  </label>

  <mat-hint *ngIf="hint">{{hint}}</mat-hint>

  <button *ngIf="suffixButton" mat-icon-button matSuffix
          [disabled]="!control.value"
          type="button"
          (click)="useSuffixBtn()">
    <mat-icon>{{ suffixUsed ? suffixButtonAlt : suffixButton }}</mat-icon>
  </button>

  <mat-icon *ngIf="suffixIcon" matSuffix
            [matTooltip]="suffixTooltip"
            [matTooltipDisabled]="!suffixTooltip"
            matTooltipClass="multiline-tooltip"
            class="suffix-icon">{{ suffixIcon }}</mat-icon>

  <button *ngIf="secondSuffixButton" mat-icon-button matSuffix
          [disabled]="!control.value"
          type="button"
          (click)="useSecondSuffixBtn()">
    <mat-icon>{{ secondSuffixUsed ? secondSuffixButtonAlt : secondSuffixButton }}</mat-icon>
  </button>

  <mat-icon *ngIf="secondSuffixIcon" matSuffix
            [matTooltip]="secondSuffixTooltip"
            [matTooltipDisabled]="!secondSuffixTooltip"
            matTooltipClass="multiline-tooltip"
            class="suffix-icon">{{ secondSuffixIcon }}</mat-icon>

  <mat-error *ngIf="control.hasError('required') && control.dirty && !control.hasError('password')" class="error">
    {{ detailName }} is required.
  </mat-error>
  <mat-error *ngIf="control.hasError('email') && control.dirty" class="error">
    {{ detailName }} should be valid.
  </mat-error>
  <mat-error *ngIf="control.hasError('pattern') && control.dirty" class="error">
    {{ detailName }} is not valid because of invalid characters.
  </mat-error>
  <mat-error *ngIf="minLength && !maxLength && control.hasError('minlength') && control.dirty" class="error">
    {{ detailName }} should contain more than {{ minLength }} characters.
  </mat-error>
  <mat-error *ngIf="min !== undefined && control.hasError('min') && control.dirty" class="error">
    {{ detailName }} should be greater than or equal to {{ min }}.
  </mat-error>
  <mat-error *ngIf="max !== undefined && control.hasError('max') && control.dirty" class="error">
    {{ detailName }} should be less than or equal to {{ max }}.
  </mat-error>
  <mat-error *ngIf="!minLength && maxLength && control.hasError('maxlength') && control.dirty" class="error">
    {{ detailName }} should contain less than {{ maxLength }} characters.
  </mat-error>
  <mat-error class="error"
    *ngIf="minLength && maxLength && (control.hasError('minlength') || control.hasError('maxlength')) && control.dirty">
    <span *ngIf="minLength === maxLength">
      {{ detailName }} should contain {{ minLength }} characters.
    </span>
    <span *ngIf="minLength !== maxLength">
      {{ detailName }} should contain between {{ minLength }} and
      {{ maxLength }} characters.
    </span>
  </mat-error>
  <mat-error *ngIf="control.hasError('password') && control.dirty" class="error">
    <span *ngIf="control.errors">{{control.errors['password']?.error}}</span>
    <span *ngIf="!control.errors">
      Incorrect {{detailName}}.
    </span>
  </mat-error>
  <mat-error *ngIf="formGroup && formGroup.hasError('mismatch')" class="error">
    Passwords do not match.
  </mat-error>

  <ng-content></ng-content>
</mat-form-field>
