import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of, timer } from 'rxjs';
import { DateUtil } from '../helper/date.helper';

@Pipe({
  name: 'durationTimeAsync'
})
export class DurationTimeAsyncPipe implements PipeTransform {
  static readonly EMIT_INTERVAL = 1000 * 60; // each minute

  transform(startDate: string, endDate?: string): Observable<string> {

    if (startDate && endDate) {
      return of(DateUtil.formatDistance(new Date(startDate), new Date(endDate)));
    }

    return timer(0, DurationTimeAsyncPipe.EMIT_INTERVAL).pipe(
      map(() => DateUtil.formatDistance(new Date(startDate), new Date())),
    )
  }

}
