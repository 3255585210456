<center-content-holder>
  <div class="center-align">
    <h1>
      <mat-icon class="title-icon">bug_report</mat-icon>
      404
    </h1>
    <h2>Page not found</h2>
    <h4>
      We're sorry, the page you requested could not be found.
      <br/>
      <br/>
      Please go back to
      <app-link [routerLink]="homeUrl"
                class="link"
                label="Home Page"></app-link>
    </h4>
  </div>
</center-content-holder>
