import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function EmailValidator(control: FormControl | AbstractControl): ValidationErrors | null {
  return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    .test(control.value) ? null : { 'email': true };
}

export function EmailValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `"${field?.formControl?.value}" is not a valid email address`;
}
