import { Injectable } from '@angular/core';
import { MenuItem } from '@common/model/MenuItem';
import { Role } from '@app/common/model/auth/Role';

interface MenuItemConfig {
  title: string;
  icon: string;
  path: string;
  roles?: Role[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuProviderService {

  private readonly menuConfig: MenuItemConfig[] = [
    {
      title: 'Time Tracker',
      icon: 'timer',
      path: 'tracking',
    },
    {
      title: 'Settings',
      icon: 'settings',
      path: 'settings',
      roles: [Role.admin],
    }
  ];

  private menuAllowed(userRoles: Role[], allowedRoles: Role[] = []) {
    if (!allowedRoles?.length) {
      return true;
    }

    return allowedRoles.map(role => userRoles.includes(role)).reduce((r1, r2) => r1 || r2);
  }

  public mainMenu(roles: Role[] = []): MenuItem[] {
    return this.menuConfig
      .filter(item => this.menuAllowed(roles, item.roles))
      .map(item => new MenuItem(item.title, item.icon, item.path));
  }

}
