import { NgModule } from '@angular/core';
import { ComponentsRoutingModule } from '@app/components/components-routing.module';
import { CommonComponentsModule } from '@common/common.module';

@NgModule({
  declarations: [],
  imports: [
    CommonComponentsModule,

    ComponentsRoutingModule
  ],
  exports: [],
})
export class ComponentsModule {
}
