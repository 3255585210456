import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@environment';
import { MenuItem } from '@common/model/MenuItem';
import { AuthService } from '@common/service/auth.service';
import { Session } from '@common/model/auth/Session';
import { MenuProviderService } from '@app/service/menu-provider.service';
import { User } from '@app/core/user';
import { Role } from '@app/common/model/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private DEFAULT_TITLE = 'Employee Portal';

  private routeEventsSubscription: Subscription | undefined;
  private breakpointChangeSubscription: Subscription | undefined;

  mainMenu: MenuItem[] = [];
  currentUrl?: string;

  authUser$ = this.authService.authUser$;
  isAuthorized$ = this.authService.authorized$;
  user?: User;
  session?: Session;

  dataLoaded = false;

  mobileView = false;

  envName = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private menuProviderService: MenuProviderService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkAuth();
    this.titleService.setTitle(this.DEFAULT_TITLE);
  }

  ngOnInit(): void {
    this.envName = environment.env != 'prod' ? ` (${environment.env})` : '';

    this.routeEventsSubscription = combineLatest([
      this.router.events,
      this.authService.authUser$
    ]).pipe(
      map(([routeEvent, authUser]) => ({ routeEvent, authUser })),
    ).subscribe(({ routeEvent, authUser }) => {
      if (routeEvent instanceof NavigationEnd) {
        this.currentUrl = routeEvent.urlAfterRedirects;
        this.prepareMenu(authUser?.role);
        this.dataLoaded = true;
      } else if (routeEvent instanceof RoutesRecognized) {
        const firstChild = routeEvent.state.root.firstChild;
        const routeData = firstChild ? firstChild.data : {};
        const innerChildData = firstChild?.firstChild ? firstChild.firstChild.data : null;

        let title = this.DEFAULT_TITLE;
        const innerChildTitle = innerChildData ? ` | ${innerChildData['title'] as string}` : '';
        if (routeData['title']) {
          title = `${routeData['title'] as string}${innerChildTitle} | ${title}`;
        }

        this.titleService.setTitle(title);
        this.metaService.updateTag({ property: 'og:title', content: title }, 'property=\'og:title\'');
        this.metaService.updateTag({ name: 'twitter:title', content: title }, 'name=\'twitter:title\'');
      }
    });

    this.breakpointChangeSubscription = this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        this.mobileView = state.matches;
      });
  }

  ngOnDestroy(): void {
    if (this.routeEventsSubscription) this.routeEventsSubscription.unsubscribe();
    if (this.breakpointChangeSubscription) this.breakpointChangeSubscription.unsubscribe();
  }

  private checkAuth() {
    this.authService.checkAuth().pipe(take(1)).subscribe(user => {
      this.prepareMenu(user?.role);
      this.dataLoaded = true;
    });
  }

  private prepareMenu(userRole?: Role) {
    this.mainMenu = userRole ? this.menuProviderService.mainMenu([userRole]) : [];
  }

  async closeSidenav(sidenav: MatSidenav) {
    if (this.mobileView) {
      await sidenav.close();
    }
  }

  getAvatarLetters(user: User): string {
    const [firstName, lastName] = user.name.split(' ');
    const avatarLetters = `${firstName[0] || ''}${lastName[0] || ''}`;

    if (!avatarLetters) {
      return 'U';
    }

    return avatarLetters;
  }

  signOut(sidenav: MatSidenav) {
    this.authService.signOut().subscribe(() => this.closeSidenav(sidenav));
  }
}
