import { FormlyFieldConfig } from '@ngx-formly/core';

export const minLengthValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `Should have atleast ${field.props?.minLength} characters`;
}

export const maxLengthValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be less than ${field.props?.maxLength} characters`;
}

export const minValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be more than ${field.props?.min}`;
}

export const maxValidationMessage = (error: any, field: FormlyFieldConfig) => {
  return `This value should be less than ${field.props?.max}`;
}

export * from './email.validator';
