import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, FetchUsersQuery, UpdateUserDto, CreateUserDto } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private url = '/users';
  constructor(private http$: HttpClient) { }

  createUser(data: CreateUserDto): Observable<User> {
    return this.http$.post<User>(this.url, data);
  }

  updateUser(employeeId: string, data: UpdateUserDto): Observable<User> {
    return this.http$.put<User>(`${this.url}/${employeeId}`, data);
  }

  fetchUsers(query?: FetchUsersQuery): Observable<User[]> {
    const params = new HttpParams({ fromObject: query });

    return this.http$.get<User[]>(this.url, { params });
  }

  fetchUser(employeeId: string): Observable<User> {
    return this.http$.get<User>(`${this.url}/${employeeId}`);
  }
}
