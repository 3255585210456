import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export abstract class SubscribableComponent implements OnDestroy {
  protected subscriptions: (() => void)[] | undefined = [];

  protected unsubscribeOnDestroy(s: Subscription) : void {
    this.subscriptions?.push(() => s.unsubscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(unsubscribe => {
      unsubscribe();
    });
    this.subscriptions = undefined;
  }
}
