import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from '../../model/MenuItem';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent {
  expanded?: boolean;

  readonly linkActiveOptions: IsActiveMatchOptions = {
    queryParams: 'exact',
    matrixParams: 'exact',
    paths: 'subset',
    fragment: 'exact',
  };

  @Input() item!: MenuItem;
  @Input() depth = 0;
  @Input() currentUrl?: string;

  @Output() childSelected = new EventEmitter<unknown>();

  get shouldBeExpanded(): boolean {
    return !!this.currentUrl && this.itemSelected(this.item, this.currentUrl);
  }

  itemSelected(item: MenuItem, url: string): boolean {
    let selected = !!item.path && url.indexOf(`/${item.path}`) === 0;
    if (!selected && item.children && item.children.length) {
      selected = item.children.map(i => this.itemSelected(i, url)).reduce((a, b) => a || b);
    }

    return selected;
  }

  onItemSelected() {
    if (!this.shouldBeExpanded) {
      this.expanded = !this.expanded;
    }

    if (!this.item.children?.length) {
      this.childSelected.emit();
    }
  }
}
