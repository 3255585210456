import { ElementRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription, timer } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { DynamicOverlay } from '../components/overlay/dynamic-overlay.service';
import { ProgressContainerComponent } from '../components/progress-container/progress-container.component';

/** Can be used to add overlay over component and global overlay.
 * Component overlay:
 *
 * this.dataLoadingProgress = this.progressService.showProgress(this.contactsTable);
 * this.progressService.detach(this.dataLoadingProgress);
 *
 * Global overlay:
 *
 * private overlay: Overlay
 *
 * this.overlay.create({
 *      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
 *      hasBackdrop: true
 *   }).attach(new ComponentPortal(ProgressContainerComponent))
 */
@Injectable()
export class ProgressService {

  constructor(private dynamicOverlay: DynamicOverlay) {
  }

  public showProgress(elRef: ElementRef, hasBackdrop = true) {
    if (elRef) {
      const result: ProgressRef = {
        subscription: null,
        overlayRef: null
      };
      result.subscription = timer(500).subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.dynamicOverlay.setContainerElement(elRef.nativeElement);
        const positionStrategy = this.dynamicOverlay.position().global().centerHorizontally().centerVertically();
        result.overlayRef = this.dynamicOverlay.create({
          positionStrategy,
          hasBackdrop
        });
        result.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
      });

      return result;
    } else {
      return null;
    }
  }

  detach(result: ProgressRef) {
    if (result) {
      result.subscription?.unsubscribe();

      if (result.overlayRef) {
        result.overlayRef.detach();
      }
    }
  }
}

export declare interface ProgressRef {
  subscription: Subscription | null;
  overlayRef: OverlayRef | null;
}
