import { Injectable, Injector } from '@angular/core';

let InjectorInstance: Injector;

@Injectable()
class InjectHelper {

  static setInjector(injector: Injector): void {
    InjectorInstance = injector;
  }
}

export { InjectorInstance, InjectHelper };
