import {Component, Input} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent  {

  @Input() letters: string | undefined;
  @Input() largeAvatar = false;

  @Input() picture: string | undefined;

}
