import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {

  @Input() control!: FormControl;
  @Input() formGroup?: FormGroup;
  @Input() type = 'text';
  @Input() label = 'Input';
  @Input() hint: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() autocomplete: string | undefined;
  @Input() required = true;
  @Input() compact = false;

  @Input() min: number | undefined;
  @Input() max: number | undefined;
  @Input() minLength: number | undefined;
  @Input() maxLength: number | undefined;
  @Input() step: number | undefined;

  @Input() errorStateMatcher: ErrorStateMatcher | undefined;
  @Input() timeErrorCode: string | undefined;
  @Input() timeStart = true;

  @Input() suffixButton: string | undefined;
  @Input() suffixButtonAlt: string | undefined;
  @Input() suffixAction?: () => void;
  @Input() suffixIcon: string | undefined;
  @Input() suffixTooltip = '';
  suffixUsed: boolean | undefined;

  @Input() secondSuffixButton: string | undefined;
  @Input() secondSuffixButtonAlt: string | undefined;
  @Input() secondSuffixAction?: () => void;
  @Input() secondSuffixIcon: string | undefined;
  @Input() secondSuffixTooltip = '';
  secondSuffixUsed: boolean | undefined;

  @Input() autoComplete: MatAutocomplete | null = null;

  @Input() name: string | undefined;

  @Output() focusLost = new EventEmitter<unknown>();
  @Output() keyUpEnter = new EventEmitter<unknown>();

  @ViewChild('input', {static: false}) inputElement!: ElementRef;

  get inputType(): string {
    return !this.suffixButton
      ? this.type
      : this.type == 'password' && this.suffixUsed ? 'text' : this.type;
  }

  get detailName(): string {
    return this.name || this.label;
  }

  useSuffixBtn() {
    if (this.suffixAction) {
      this.suffixAction();
    } else {
      this.suffixUsed = !this.suffixUsed;
    }
  }

  useSecondSuffixBtn() {
    if (this.secondSuffixAction) {
      this.secondSuffixAction();
    } else {
      this.secondSuffixUsed = !this.secondSuffixUsed;
    }
  }

  public setFocus() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.inputElement.nativeElement.focus();
  }

  handleOnBlur() {
    this.focusLost.emit();
  }

  handleKeyUpEnter() {
    this.keyUpEnter.emit();
  }
}
