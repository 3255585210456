<ng-container *ngIf="{ isAuthorized: isAuthorized$ | async, authUser: authUser$ | async } as vm">
  <div class="container">
    <mat-toolbar color="primary" class="top-toolbar">
      <mat-toolbar-row class="toolbar-row">
        <app-button *ngIf="vm.isAuthorized && mobileView"
                    class="menu-btn"
                    icon="menu"
                    [secondary]="true"
                    (clicked)="sidenav.toggle()"></app-button>
        <div class="fill-space"></div>
        <div [class.mobile-title]="mobileView"><img src="./assets/logo.png" alt="logo" class="logo"/></div>
        <div>RV Employee Portal {{envName}}</div>
        <div class="fill-space"></div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container" autosize>
      <mat-sidenav #sidenav
                  class="main-sidenav" [class.mobile]="mobileView"
                  [fixedInViewport]="mobileView"
                  [mode]="mobileView ? 'over' : 'side'"
                  [opened]="!mobileView && vm.isAuthorized"
                  role="navigation">

                  <ng-container *ngIf="vm.authUser">

                    <div class="profile-section">

                      <div class="profile-info center">
                        <avatar [letters]="getAvatarLetters(vm.authUser)"></avatar>
                      </div>

                    </div>
                    <div class="profile-section">
                      <div class="profile-name">{{ vm.authUser.name }}</div>
                      <div class="profile-role">[{{ vm.authUser.role }}]</div>
                    </div>

                  </ng-container>

        <app-link *ngIf="vm.isAuthorized"
                  class="sign-out"
                  (click)="signOut(sidenav)"
                  label="Sign out"
                  icon="logout"></app-link>

        <hr>

        <mat-nav-list>
          <span *ngIf="!dataLoaded" class="center">
            <mat-spinner color="primary" [diameter]="30"></mat-spinner>
          </span>

          <menu-list-item *ngFor="let item of mainMenu" class="menu-item no-focus"
                          [item]="item" [currentUrl]="currentUrl"
                          (childSelected)="closeSidenav(sidenav)"></menu-list-item>
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content role="main" class="main-content-holder" [class.mobile]="mobileView">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>
