import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '@app/common/model/auth';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/tracking/employees'
  },
  {
    path: 'tracking',
    loadChildren: () => import('./time-tracking/time-tracking.module').then(m => m.TimeTrackingModule),
    data: {
      title: 'Time Tracking',
      roles: [Role.admin, Role.supervisor, Role.lead],
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    data: {
      title: 'Settings',
      roles: [Role.admin],
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ComponentsRoutingModule {
}
