import { baseEnvironment, Environment } from './environment.base';

export const environment: Environment = {
  ...baseEnvironment,

  env: 'stage',

  api: {
    host: 'https://api.stage.employee-portal.rvprintfactory.com'
  },

  auth: {
    region: 'eu-central-1',
    pool_id: 'eu-central-1_YyUnHXg0q',
    client_id: 'bf7s7gdllo3u9fok0l8l9l31l',
    EMPLOYEE_DEFAULT_PASSPHRASE: '1Password',
  },
};
