import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../helper/date.helper';

@Pipe({
  name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {

  transform(value: string | Date | undefined, format = 'yyyy-MM-dd'): string {
    if (!value) {
      return '';
    }

    if (DateUtil.isToday(new Date(value))) {
      return 'Today';
    }

    if (DateUtil.isYesterday(new Date(value))) {
      return 'Yesterday';
    }

    return DateUtil.format(new Date(value), format);
  }

}
