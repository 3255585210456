import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addQuarters,
  addSeconds,
  addWeeks,
  addYears,
  endOfDay,
  format,
  formatDistance,
  isSameDay,
  isToday,
  isYesterday,
  milliseconds,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfQuarter,
  startOfSecond,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMilliseconds,
  subMinutes,
  subMonths,
  subQuarters,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';

enum DateOperation {
  add = 'add',
  subtract = 'subtract',
  startOf = 'startOf',
}

export enum DateUnit {
  millisecond = 'millisecond',
  milliseconds = 'millisecond',
  second = 'second',
  seconds = 'second',
  minute = 'minute',
  minutes = 'minute',
  hour = 'hour',
  hours = 'hour',
  day = 'day',
  days = 'day',
  week = 'week',
  weeks = 'week',
  month = 'month',
  months = 'month',
  quarter = 'quarter',
  quarters = 'quarter',
  year = 'year',
  years = 'year',
}

export class DateUtil {

  static readonly DATE_FORMAT = 'dd MMM yyyy';
  static readonly DATE_TIME_FORMAT = 'dd MMM yyyy H:m';

  private static execute(op: DateOperation, date: Date, unit: DateUnit, value = 0) {
    if (!Object.values(DateOperation).includes(op)) {
      throw Error(`Invalid date operation: ${op}`);
    }

    switch (unit) {

      case DateUnit.millisecond:
      case DateUnit.milliseconds: {
        if (op === DateOperation.add) {
          return addMilliseconds(date, value);
        }

        if (op === DateOperation.subtract) {
          return subMilliseconds(date, value);
        }

        if (op === DateOperation.startOf) {
          throw Error('Milliseconds does not have a start value');
        }

        break;
      }

      case DateUnit.second:
      case DateUnit.seconds: {
        if (op === DateOperation.add) {
          return addSeconds(date, value);
        }

        if (op === DateOperation.subtract) {
          return subSeconds(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfSecond(date);
        }

        break;
      }

      case DateUnit.minute:
      case DateUnit.minutes: {
        if (op === DateOperation.add) {
          return addMinutes(date, value);
        }

        if (op === DateOperation.subtract) {
          return subMinutes(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfMinute(date);
        }

        break;
      }

      case DateUnit.hour:
      case DateUnit.hours: {
        if (op === DateOperation.add) {
          return addHours(date, value);
        }

        if (op === DateOperation.subtract) {
          return subHours(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfHour(date);
        }

        break;
      }

      case DateUnit.day:
      case DateUnit.days: {
        if (op === DateOperation.add) {
          return addDays(date, value);
        }

        if (op === DateOperation.subtract) {
          return subDays(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfDay(date);
        }

        break;
      }

      case DateUnit.week:
      case DateUnit.weeks: {
        if (op === DateOperation.add) {
          return addWeeks(date, value);
        }

        if (op === DateOperation.subtract) {
          return subWeeks(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfWeek(date);
        }

        break;
      }

      case DateUnit.month:
      case DateUnit.months: {
        if (op === DateOperation.add) {
          return addMonths(date, value);
        }

        if (op === DateOperation.subtract) {
          return subMonths(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfMonth(date);
        }

        break;
      }

      case DateUnit.quarter:
      case DateUnit.quarters: {
        if (op === DateOperation.add) {
          return addQuarters(date, value);
        }

        if (op === DateOperation.subtract) {
          return subQuarters(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfQuarter(date);
        }

        break;
      }

      case DateUnit.year:
      case DateUnit.years: {
        if (op === DateOperation.add) {
          return addYears(date, value);
        }

        if (op === DateOperation.subtract) {
          return subYears(date, value);
        }

        if (op === DateOperation.startOf) {
          return startOfYear(date);
        }

        break;
      }
    }

    throw Error(`Invalid date unit: ${unit}`);
  }

  static add(date: Date, value: number, unit: DateUnit): Date {
    return this.execute(DateOperation.add, date, unit, value);
  }

  static subtract(date: Date, value: number, unit: DateUnit): Date {
    return this.execute(DateOperation.subtract, date, unit, value);
  }

  static startOfDay(date: Date = new Date()): Date {
    return startOfDay(date);
  }

  static endOfDay(date: Date): Date {
    return endOfDay(date);
  }

  static isSameDay(leftDate: Date, rightDate: Date): boolean {
    return isSameDay(leftDate, rightDate);
  }

  static isToday(date: Date): boolean {
    return isToday(date);
  }

  static isYesterday(date: Date): boolean {
    return isYesterday(date);
  }

  static format(date: Date, formatter: string): string {
    return format(date, formatter);
  }

  static formatDistance(fromDate: Date, toDate: Date,): string {
    return formatDistance(fromDate, toDate);
  }

  static toDateString(date: Date): string {
    return this.format(date, this.DATE_FORMAT);
  }

  static generateDayRange(startDate: Date, endDate: Date, interval: DateUnit, format?: string): string[] {
    const range = [];
    const millis = milliseconds({ [`${interval}s`]: 1 });
    const period = Math.abs(this.startOfDay(startDate).getTime() - this.startOfDay(endDate).getTime()) / millis;

    let currDate = startDate;

    for (let i = 0; i <= period; i++) {
      currDate = this.execute(DateOperation.startOf, currDate, interval);
      range.push(this.format(currDate, format || this.DATE_FORMAT));

      if (startDate < endDate) {
        currDate = this.add(currDate, 1, interval);
      } else {
        currDate = this.subtract(currDate, 1, interval);
      }
    }

    return range;
  }

  static fromTimeToDate(timeString: string, date = new Date()): Date {
    try {
    const [h, m, format] = timeString.replace(':', ' ').split(' ');
    let hh = Number(h);
    const mm = Number(m);

    if (format == 'PM' && hh < 12) hh = hh + 12;
    if (format == 'AM' && hh == 12) hh = hh - 12;

    date.setHours(hh);
    date.setMinutes(mm);
    date.setSeconds(0);

    return date;
    } catch (error) {
      throw new Error(`Can not convert time ${timeString} to date`);
    }
  }
}
