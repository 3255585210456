<ng-container *ngIf="!hidden">
  <div (click)="proxyClick($event)" [ngStyle]="{ display: 'inline-block', padding: padding + 'px' }">
    <ng-container [ngSwitch]="appearance">
      <ng-container *ngSwitchCase="'flat'">
        <button mat-flat-button [color]="color" [disabled]="disabled" (click)="click()" [matBadge]="badge" [matBadgeColor]="badgeColor">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'raised'">
        <button mat-raised-button [color]="color" [disabled]="disabled" (click)="click()" [matBadge]="badge" [matBadgeColor]="badgeColor">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'basic'">
        <button mat-button [color]="color" [disabled]="disabled" (click)="click()" [matBadge]="badge" [matBadgeColor]="badgeColor">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'stroked'">
        <button mat-stroked-button [color]="color" [disabled]="disabled" (click)="click()" [matBadge]="badge" [matBadgeColor]="badgeColor">
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'fab'">
        <button mat-fab [color]="color" [disabled]="disabled" (click)="click()">
          <mat-icon [matBadge]="badge" [matBadgeColor]="badgeColor">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </mat-icon>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'fab-extended'">
        <button mat-fab extended [color]="color" [disabled]="disabled" (click)="click()">
          <mat-icon [matBadge]="badge" [matBadgeColor]="badgeColor">
            <ng-container [ngTemplateOutlet]="contentIcon"></ng-container>
          </mat-icon>
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'mini-fab'">
        <button mat-mini-fab [color]="color" [disabled]="disabled" (click)="click()">
          <mat-icon [matBadge]="badge" [matBadgeColor]="badgeColor">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </mat-icon>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'icon'">
        <button mat-icon-button [color]="color" [disabled]="disabled" (click)="click()">
          <mat-icon [matBadge]="badge" [matBadgeColor]="badgeColor">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
          </mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<!-- Used with fab-extended button. Should contain icon with attribute 'icon' to be projected as icon part of button -->
<ng-template #contentIcon>
  <ng-content select="[icon]"></ng-content>
</ng-template>

<ng-template #content>
  <ng-container *ngIf="!loading; else loadingTpl">
    <ng-content></ng-content>
  </ng-container>
</ng-template>

<ng-template #loadingTpl>
  <div class="loading rotate">
    <mat-icon>sync</mat-icon>
  </div>
</ng-template>

<ng-template #confirmationDialogTpl>
  <ng-container *ngIf="template; else defaultConfirmationTpl">
    <ng-container
      *ngTemplateOutlet="template; context: {
        $implicit: templateContext || null,
        decline: declineFn,
        confirm: confirmFn
      }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #defaultConfirmationTpl>
  <h1 mat-dialog-title>{{ confirmationMessage }}</h1>
  <div mat-dialog-content>{{ confirmationDescription }}</div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-flat-button (click)="dialogRef?.close([false])">Cancel</button>
    <button mat-flat-button color="warn" cdkFocusInitial (click)="dialogRef?.close([true])">Confirm</button>
  </div>
</ng-template>
